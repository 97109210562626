import { useState } from "react";
import { Gallery } from "react-grid-gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { images, CustomImage } from "./images";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./App.css";

import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Back from "./Back";

const slides = images.map(({ src, key, width, height, srcSet}) => ({
  src,
  key,
  width,
  height,
  srcSet: srcSet?.map((image) => ({
    src: image.src,
    width: image.width,
    height: image.height
  }))
}));

export default function App() {
  const [index, setIndex] = useState(-1);

  const handleClick = (index: number, item: CustomImage) => setIndex(index);

  return (
    <div className="container">
      <Back/>
      <Gallery
        images={images}
        onClick={handleClick}
        enableImageSelection={false}
      />
      <Lightbox
        styles={{ root: { "--yarl__slide_title_color": "red" } }}
        slides={slides}
        open={index >= 0}
        index={index}
        plugins={[Fullscreen, Thumbnails]}
        thumbnails={{
          position:"end",
          width:60,
          height:70,
          border:false,
          borderRadius:0,
          padding:0,
          gap:3,
        }}
        close={() => setIndex(-1)}
      />
    </div>
  );
}