import React from 'react'

function Back() {
  return (
    <div>
        <a href="http://xdmr.us" class="lets-talk">← back to xdMR.us (portfolio website) </a>
    </div>
  )
}

export default Back