import { Image } from "react-grid-gallery";

// export interface CustomImage extends Image {
//   original: string;
// }

export const images: CustomImage[] = [
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675033560/xdMR%20Gallery/Screenshot_2023-01-15_at_12.21.23_AM.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675033560/xdMR%20Gallery/Screenshot_2023-01-15_at_12.21.23_AM.png",
    width: 1562,
    height: 1216,
    alt: "image 1",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675033560/xdMR%20Gallery/Screenshot_2023-01-15_at_12.21.23_AM.png",
        width: 700,
        height: 600,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "CSS", title: "CSS" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "Design System",
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675035204/xdMR%20Gallery/ccremailBig.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675035204/xdMR%20Gallery/ccremailBig.png",
    width: 1408,
    height: 1056,
    alt: "image 1",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1400/v1675035204/xdMR%20Gallery/ccremailBig.png",
        width: 700,
        height: 600,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "Email Design", title: "Email Design" }],
    caption: "Design System",
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_300/v1675032840/xdMR%20Gallery/Unsubscriber_CenterCentre-UIE.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675032840/xdMR%20Gallery/Unsubscriber_CenterCentre-UIE.png",
    width: 511,
    height: 542,
    alt: "image 1",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675032840/xdMR%20Gallery/Unsubscriber_CenterCentre-UIE.png",
        width: 1000,
        height: 1000,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      {
        value: "Email Unsubsribe",
        title: "CenterCentre Email Unsubscribe Box",
      },
    ],
    caption: "Unsubscribe",
    customOverlay: (
      <div className="custom-overlay__caption">
        <div>
          When clicking on unsubscribe link in their email customer get's this
          pannel.
        </div>
      </div>
    ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675038047/xdMR%20Gallery/mailhydra.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/mailhydra.png",
    width: 1568,
    height: 1142,
    alt: "Mail Hydra",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675038047/xdMR%20Gallery/mailhydra.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675038047/xdMR%20Gallery/mailhydra.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675038047/xdMR%20Gallery/mailhydra.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675038047/xdMR%20Gallery/mailhydra.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "Logo Design", title: "Logo Design" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "Mail Hydra",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675038550/xdMR%20Gallery/cherokee1.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038550/xdMR%20Gallery/cherokee1.png",
    width: 1920,
    height: 2145,
    alt: "ThinkCherokee",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675038550/xdMR%20Gallery/cherokee1.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675038550/xdMR%20Gallery/cherokee1.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675038550/xdMR%20Gallery/cherokee1.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675038550/xdMR%20Gallery/cherokee1.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "ThinkCherokee",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675038773/xdMR%20Gallery/cherokee2.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675038773/xdMR%20Gallery/cherokee2.png",
    width: 1077,
    height: 1098,
    alt: "ThinkCherokee",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675038773/xdMR%20Gallery/cherokee2.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675038773/xdMR%20Gallery/cherokee2.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675038773/xdMR%20Gallery/cherokee2.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675038773/xdMR%20Gallery/cherokee2.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "ThinkCherokee", title: "ThinkCherokee" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "ThinkCherokee",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039049/xdMR%20Gallery/cherokee3.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039049/xdMR%20Gallery/cherokee3.png",
    width: 1158,
    height: 1061,
    alt: "ThinkCherokee",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039049/xdMR%20Gallery/cherokee3.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039049/xdMR%20Gallery/cherokee3.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039049/xdMR%20Gallery/cherokee3.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039049/xdMR%20Gallery/cherokee3.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "ThinkCherokee",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039222/xdMR%20Gallery/centercentre_paid_program1.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039222/xdMR%20Gallery/centercentre_paid_program1.png",
    width: 1007,
    height: 533,
    alt: "The Center Centre UX Strategy Program",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039222/xdMR%20Gallery/centercentre_paid_program1.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039222/xdMR%20Gallery/centercentre_paid_program1.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039222/xdMR%20Gallery/centercentre_paid_program1.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039222/xdMR%20Gallery/centercentre_paid_program1.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "The Center Centre UX Strategy Program",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039365/xdMR%20Gallery/centercentre_paid_program2.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039365/xdMR%20Gallery/centercentre_paid_program2.png",
    width: 2050,
    height: 1223,
    alt: "The Center Centre UX Strategy Program Payment Plan",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039365/xdMR%20Gallery/centercentre_paid_program2.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039365/xdMR%20Gallery/centercentre_paid_program2.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039365/xdMR%20Gallery/centercentre_paid_program2.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039365/xdMR%20Gallery/centercentre_paid_program2.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "The Center Centre UX Strategy Program - Payment Plan",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039486/xdMR%20Gallery/codingllama_logo.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039486/xdMR%20Gallery/codingllama_logo.png",
    width: 2000,
    height: 1186,
    alt: "Coding Llama - Logo",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039486/xdMR%20Gallery/codingllama_logo.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039486/xdMR%20Gallery/codingllama_logo.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039486/xdMR%20Gallery/codingllama_logo.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039486/xdMR%20Gallery/codingllama_logo.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "Logo Design", title: "Logo Design" }],
    caption: "Coding LLama logo design",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039555/xdMR%20Gallery/codingllama.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039555/xdMR%20Gallery/codingllama.png",
    width: 1410,
    height: 850,
    alt: "Coding Llama - Web Design",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039555/xdMR%20Gallery/codingllama.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039555/xdMR%20Gallery/codingllama.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039555/xdMR%20Gallery/codingllama.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039555/xdMR%20Gallery/codingllama.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "Coding llama",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039610/xdMR%20Gallery/kliknem.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039610/xdMR%20Gallery/kliknem.png",
    width: 1300,
    height: 961,
    alt: "Book Cover Design for Kliknem",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039610/xdMR%20Gallery/kliknem.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039610/xdMR%20Gallery/kliknem.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039610/xdMR%20Gallery/kliknem.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039610/xdMR%20Gallery/kliknem.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "eBook Cover Design", title: "eBook Cover Design" }],
    caption: "eBook Cover Design",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039706/xdMR%20Gallery/isuzu.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039706/xdMR%20Gallery/isuzu.png",
    width: 1121,
    height: 642,
    alt: "UX/UI for Isuzu.ba",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039706/xdMR%20Gallery/isuzu.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039706/xdMR%20Gallery/isuzu.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039706/xdMR%20Gallery/isuzu.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039706/xdMR%20Gallery/isuzu.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "UX/UI", title: "UX/UI" }],
    caption: "UX/UI for Isuzu.ba",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

    {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675581305/xdMR%20Gallery/Isuzu-ba-trucks.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675581305/xdMR%20Gallery/Isuzu-ba-trucks.png",
    width: 1376, height: 652 ,
    alt: "Isuzu Trucks",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675581305/xdMR%20Gallery/Isuzu-ba-trucks.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675581305/xdMR%20Gallery/Isuzu-ba-trucks.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675581305/xdMR%20Gallery/Isuzu-ba-trucks.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675581305/xdMR%20Gallery/Isuzu-ba-trucks.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },

    ],
    caption: "xxxx",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675581364/xdMR%20Gallery/Isuzu-ba-location.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675581364/xdMR%20Gallery/Isuzu-ba-location.png",
    width: 1385, height: 731 ,
    alt: "Isuzu - Location , Map",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675581364/xdMR%20Gallery/Isuzu-ba-location.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675581364/xdMR%20Gallery/Isuzu-ba-location.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675581364/xdMR%20Gallery/Isuzu-ba-location.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675581364/xdMR%20Gallery/Isuzu-ba-location.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },

    ],
    caption: "Isuzu - Location, Map",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675581437/xdMR%20Gallery/metago-mazda-isuzu.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675581437/xdMR%20Gallery/metago-mazda-isuzu.png",
    width: 1216, height: 1122 ,
    alt: "Metago",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675581437/xdMR%20Gallery/metago-mazda-isuzu.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675581437/xdMR%20Gallery/metago-mazda-isuzu.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675581437/xdMR%20Gallery/metago-mazda-isuzu.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675581437/xdMR%20Gallery/metago-mazda-isuzu.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },

    ],
    caption: "Metago",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },






  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039787/xdMR%20Gallery/jobad.jpg",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039787/xdMR%20Gallery/jobad.jpg",
    width: 1500,
    height: 1000,
    alt: "xxxx",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039787/xdMR%20Gallery/jobad.jpg",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039787/xdMR%20Gallery/jobad.jpg",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039787/xdMR%20Gallery/jobad.jpg",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039787/xdMR%20Gallery/jobad.jpg",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Social Media", title: "Social Media" },
      { value: "Poster Design", title: "Poster Design" },
    ],
    caption: "Poster Design for Social Media - Linkedin Advertising Campaign",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039885/xdMR%20Gallery/leadersofawesomness.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675039885/xdMR%20Gallery/leadersofawesomness.png",
    width: 1331,
    height: 665,
    alt: "Leaders Of Awesomness Community Poster Design",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675039885/xdMR%20Gallery/leadersofawesomness.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675039885/xdMR%20Gallery/leadersofawesomness.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675039885/xdMR%20Gallery/leadersofawesomness.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675039885/xdMR%20Gallery/leadersofawesomness.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "Social Media", title: "Social Media" },
    ],
    caption: "Leaders Of Awesomness Community Poster Design",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675124514/xdMR%20Gallery/stellar-back.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675124514/xdMR%20Gallery/stellar-back.png",
    width: 1200,
    height: 709,
    alt: "Stellar Landing Logo",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675124514/xdMR%20Gallery/stellar-back.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675124514/xdMR%20Gallery/stellar-back.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675124514/xdMR%20Gallery/stellar-back.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675124514/xdMR%20Gallery/stellar-back.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "Logo Design", title: "Logo Design" }],
    caption: "Stellar Landing Logo",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675124696/xdMR%20Gallery/stellar-landing-card.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675124696/xdMR%20Gallery/stellar-landing-card.png",
    width: 1200,
    height: 2031,
    alt: "Stellar Landing Card",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675124696/xdMR%20Gallery/stellar-landing-card.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675124696/xdMR%20Gallery/stellar-landing-card.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675124696/xdMR%20Gallery/stellar-landing-card.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675124696/xdMR%20Gallery/stellar-landing-card.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "Card Design", title: "Card Design" }],
    caption: "StellarLanding",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675124794/xdMR%20Gallery/IMG_6890.jpg",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675124794/xdMR%20Gallery/IMG_6890.jpg",
    width: 3958,
    height: 2253,
    alt: "Promiger card ",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675124794/xdMR%20Gallery/IMG_6890.jpg",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675124794/xdMR%20Gallery/IMG_6890.jpg",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675124794/xdMR%20Gallery/IMG_6890.jpg",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675124794/xdMR%20Gallery/IMG_6890.jpg",
        width: 2048,
        height: 1365,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_3840/v1675124794/xdMR%20Gallery/IMG_6890.jpg",
        width: 3840,
        height: 2560,
      },
    ],
    tags: [{ value: "Promiger", title: "Promiger" }],
    caption: "Promige car",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675124885/xdMR%20Gallery/index.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/xxx",
    width: 1242,
    height: 2208,
    alt: "Promiger",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675124885/xdMR%20Gallery/index.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675124885/xdMR%20Gallery/index.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675124885/xdMR%20Gallery/index.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675124885/xdMR%20Gallery/index.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "Web Design", title: "Web Design" }],
    caption: "Promiger",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125399/xdMR%20Gallery/light-background.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675125399/xdMR%20Gallery/light-background.png",
    width: 1920,
    height: 1080,
    alt: "xxxx",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125399/xdMR%20Gallery/light-background.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675125399/xdMR%20Gallery/light-background.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675125399/xdMR%20Gallery/light-background.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675125399/xdMR%20Gallery/light-background.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "Logo Design", title: "Logo Design" }],
    caption: "xxxx",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125597/xdMR%20Gallery/junior-work-LOGO.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675125597/xdMR%20Gallery/junior-work-LOGO.png",
    width: 1000,
    height: 1000,
    alt: "Junior.Work",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125597/xdMR%20Gallery/junior-work-LOGO.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675125597/xdMR%20Gallery/junior-work-LOGO.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675125597/xdMR%20Gallery/junior-work-LOGO.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675125597/xdMR%20Gallery/junior-work-LOGO.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "Logo Design", title: "Logo Design" }],
    caption: "Junior.Work Logo",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125697/xdMR%20Gallery/juniorwork.jpg",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675125697/xdMR%20Gallery/juniorwork.jpg",
    width: 2000,
    height: 821,
    alt: "JuniorWork",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125697/xdMR%20Gallery/juniorwork.jpg",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675125697/xdMR%20Gallery/juniorwork.jpg",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675125697/xdMR%20Gallery/juniorwork.jpg",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675125697/xdMR%20Gallery/juniorwork.jpg",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [{ value: "Web Design", title: "Web Design" }],
    caption: "JuniorWork",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125836/xdMR%20Gallery/book-ba-logo.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675125836/xdMR%20Gallery/book-ba-logo.png",
    width: 1200,
    height: 876,
    alt: "Book.ba",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125836/xdMR%20Gallery/book-ba-logo.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675125836/xdMR%20Gallery/book-ba-logo.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675125836/xdMR%20Gallery/book-ba-logo.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675125836/xdMR%20Gallery/book-ba-logo.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "Logo Design", title: "Logo Design" },
    ],
    caption: "book ba",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125913/xdMR%20Gallery/spelltell-logo.png",
    original:
      "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675125913/xdMR%20Gallery/spelltell-logo.png",
    width: 1000,
    height: 1000,
    alt: "xxxx",
    srcSet: [
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675125913/xdMR%20Gallery/spelltell-logo.png",
        width: 320,
        height: 213,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675125913/xdMR%20Gallery/spelltell-logo.png",
        width: 640,
        height: 427,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675125913/xdMR%20Gallery/spelltell-logo.png",
        width: 1200,
        height: 800,
      },
      {
        src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675125913/xdMR%20Gallery/spelltell-logo.png",
        width: 2048,
        height: 1365,
      },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "Logo Design", title: "Logo Design" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "xxxx",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },




    {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675649545/xdMR%20Gallery/spelltell-interface.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675649545/xdMR%20Gallery/spelltell-interface.png",
    width: 1238, height: 1059 ,
    alt: "SpellTell Inteface",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675649545/xdMR%20Gallery/spelltell-interface.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675649545/xdMR%20Gallery/spelltell-interface.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675649545/xdMR%20Gallery/spelltell-interface.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675649545/xdMR%20Gallery/spelltell-interface.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },

    ],
    caption: "SpellTell Inteface",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675649640/xdMR%20Gallery/spelltell-practice.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675649640/xdMR%20Gallery/spelltell-practice.png",
    width: 1223, height: 1227 ,
    alt: "SpellTell - Practice",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675649640/xdMR%20Gallery/spelltell-practice.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675649640/xdMR%20Gallery/spelltell-practice.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675649640/xdMR%20Gallery/spelltell-practice.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675649640/xdMR%20Gallery/spelltell-practice.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },

    ],
    caption: "SpellTell - Practice",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675649640/xdMR%20Gallery/spelltell-settings.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675649640/xdMR%20Gallery/spelltell-settings.png",
    width: 1202, height: 744 ,
    alt: "Spelltell - Settings",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675649640/xdMR%20Gallery/spelltell-settings.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675649640/xdMR%20Gallery/spelltell-settings.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675649640/xdMR%20Gallery/spelltell-settings.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675649640/xdMR%20Gallery/spelltell-settings.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },

    ],
    caption: "Spelltell - Settings",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },
  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675649640/xdMR%20Gallery/spell-tell-stats.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675649640/xdMR%20Gallery/spell-tell-stats.png",
    width: 1213, height: 839 ,
    alt: "SpellTell - Stats",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675649640/xdMR%20Gallery/spell-tell-stats.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675649640/xdMR%20Gallery/spell-tell-stats.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675649640/xdMR%20Gallery/spell-tell-stats.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675649640/xdMR%20Gallery/spell-tell-stats.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "Web Design", title: "Web Design" },
      { value: "UX/UI", title: "UX/UI" },
    ],
    caption: "SpellTell - Stats",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },







  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675390366/xdMR%20Gallery/metrics.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/xxx",
    width: 2880, height: 1349,
    alt: "Landing page for - UX Metrics Your Stakeholders Can't Ignore - Intensive",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675390366/xdMR%20Gallery/metrics.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675390366/xdMR%20Gallery/metrics.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675390366/xdMR%20Gallery/metrics.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675390366/xdMR%20Gallery/metrics.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "UX/UI", title: "UX/UI" },
      { value: "Web Design", title: "Web Design" },
    ],
    caption: "Landing page for - UX Metrics Your Stakeholders Can't Ignore - Intensivex",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675390537/xdMR%20Gallery/mobile-deskto-metrics-text.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675390537/xdMR%20Gallery/mobile-deskto-metrics-text.png",
    width: 1313, height: 952 ,
    alt: "Content Ilustration for - UX Metrics Your Stakeholders Can't Ignore - Intensivex",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675390537/xdMR%20Gallery/mobile-deskto-metrics-text.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675390537/xdMR%20Gallery/mobile-deskto-metrics-text.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675390537/xdMR%20Gallery/mobile-deskto-metrics-text.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675390537/xdMR%20Gallery/mobile-deskto-metrics-text.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "UX/UI", title: "UX/UI" },
      { value: "Web Design", title: "Web Design" },
    ],
    caption: "Content Ilustration for  - UX Metrics Your Stakeholders Can't Ignore - Intensive",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  {
    src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675390678/xdMR%20Gallery/metrcis-plans.png",
    original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/v1675390678/xdMR%20Gallery/metrcis-plans.png",
    width: 1440, height: 2455 ,
    alt: "Plans  - UX Metrics Your Stakeholders Can't Ignore - Intensive",
    srcSet: [
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/v1675390678/xdMR%20Gallery/metrcis-plans.png", width: 320, height: 213 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/v1675390678/xdMR%20Gallery/metrcis-plans.png", width: 640, height: 427 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/v1675390678/xdMR%20Gallery/metrcis-plans.png",  width: 1200, height: 800 },
      { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/v1675390678/xdMR%20Gallery/metrcis-plans.png",  width: 2048, height: 1365 },
      // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
    ],
    tags: [
      { value: "UX/UI", title: "UX/UI" },
      { value: "Web Design", title: "Web Design" },
    ],
    caption: "Plans  - UX Metrics Your Stakeholders Can't Ignore - Intensive",
    // customOverlay: (
    //   <div className="custom-overlay__caption">
    //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
    //   </div>
    // ),
  },

  // {
  //   src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/xxx",
  //   original: "https://res.cloudinary.com/dnqzjb5qs/image/upload/v1675038047/xdMR%20Gallery/xxx",
  //   width: 1568, height: 1142 ,
  //   alt: "xxxx",
  //   srcSet: [
  //     { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_320/xxxx", width: 320, height: 213 },
  //     { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_600/xxxx", width: 640, height: 427 },
  //     { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_1200/xxxx",  width: 1200, height: 800 },
  //     { src: "https://res.cloudinary.com/dnqzjb5qs/image/upload/c_scale,w_2048/xxxx",  width: 2048, height: 1365 },
  //     // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
  //   ],
  //   tags: [
  //     { value: "Web Design", title: "Web Design" },
  //     { value: "Logo Design", title: "Logo Design" },
  //     { value: "UX/UI", title: "UX/UI" },

  //   ],
  //   caption: "xxxx",
  //   // customOverlay: (
  //   //   <div className="custom-overlay__caption">
  //   //     <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
  //   //   </div>
  //   // ),
  // },

  // {
  //   src: "https://via.placeholder.com/600/09f.png/fff",
  //   original: "https://via.placeholder.com/600/09f.png/fff",
  //   width: 600, height: 600 ,
  //   alt: "image 1",
  //   srcSet: [
  //     { src: "https://via.placeholder.com/300/09f.png/fff", width: 320, height: 213 },
  //     { src: "https://via.placeholder.com/600/09f.png/fff", width: 640, height: 427 },
  //     { src: "https://via.placeholder.com/1200/09f.png/fff",  width: 1200, height: 800 },
  //     { src: "https://via.placeholder.com/2000/09f.png/fff",  width: 2048, height: 1365 },
  //     // { src: "https://via.placeholder.com/3000/09f.png/fff",  width: 3840, height: 2560 },
  //   ],
  //   tags: [
  //     { value: "Much more", title: "Much More" },
  //     { value: "To come", title: "to come" },
  //   ],
  //   caption: "Unsubscribe",
  //   customOverlay: (
  //     <div className="custom-overlay__caption">
  //       <div>When clicking on unsubscribe link in their email customer get's this pannel.</div>
  //     </div>
  //   ),
  // },
];
